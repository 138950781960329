import React, { FC, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Loading from "../../loading/loading";
import { useUserInfoForPool } from "../../../hooks/farm/useUserInfoForPool";
import Button from "../../common/button/button";
import {
  useStakedAmount,
  useUnstake,
} from "../../../hooks/album/farm/useStake";
import Modal from "../../common/modal/modal";
import ModalTitle from "../../common/modal/modalTitle";
import ModalSubtitle from "../../common/modal/modalSubtitle";
import { useEffect } from "react";

interface StakingMyRewardsProps {
  user: any;
  poolID: number;
}

const StakingMyRewards: FC<StakingMyRewardsProps> = ({ user, poolID }) => {
  const [poolRewards] = useUserInfoForPool(user?.uid, poolID);
  const [rewards, setRewards] = useState<number>();
  const stakedAmount = useStakedAmount(poolID, user?.uid);
  const [unstake, unstakeState] = useUnstake(poolID);

  const [showConfirm, setShowConfirm] = useState(false);

  const handleClaim = () => {
    unstake(stakedAmount);
  };

  useEffect(() => {
    if (unstakeState.status === "Success") {
      setRewards(0);
    } else if (unstakeState.status === "Mining") {
      setShowConfirm(false);
    }
  }, [unstakeState]);

  useEffect(() => {
    if (poolRewards?.pendingRewards) {
      setRewards(poolRewards.pendingRewards);
    }
  }, [poolRewards]);

  return (
    <div className="flex flex-col w-full sm:px-16 md:px-0">
      <Modal isOpen={showConfirm} onClose={() => setShowConfirm(false)}>
        <ModalTitle>
          <h2>Claiming also Unstakes your tokens</h2>
        </ModalTitle>
        <div className="flex flex-col space-y-24 mt-24">
          <ModalSubtitle>
            When you claim your SZNS token your staked tokens will unstake as
            well. Are you sure you wish to continue?
          </ModalSubtitle>
          <div className="flex flex-row mt-32 justify-between space-x-16">
            <Button
              size="md"
              width="full"
              onClick={() => setShowConfirm(false)}
              fill={false}
            >
              Cancel
            </Button>
            <Button size="md" width="full" onClick={handleClaim}>
              Continue
            </Button>
          </div>
        </div>
      </Modal>
      <h1 className="pb-24 text-dark-white-64">My rewards</h1>
      <div className="flex flex-row">
        <div
          className="flex flex-grow bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 my-24 w-full">
            <div className="flex flex-row w-full pb-16 text-dark-white-64">
              <p className="caption flex flex-grow">SZNS BALANCE</p>
              <div>
                <InfoIcon />
              </div>
            </div>
            {rewards ? (
              <h2 className="pb-16 text-dark-light-grey fade-in">
                {rewards.toLocaleString()} SZNS
              </h2>
            ) : (
              <h2 className="pb-16 text-dark-light-grey animate-pulse">
                0 SZNS
              </h2>
            )}
            <Button
              fill={true}
              width="full"
              onClick={() => setShowConfirm(true)}
              disabled={Boolean(
                !stakedAmount ||
                  stakedAmount === 0 ||
                  !user ||
                  !rewards ||
                  rewards <= 0 ||
                  unstakeState?.status === "Mining"
              )}
            >
              {unstakeState.status === "Mining" ? (
                <Loading size={24} />
              ) : (
                "Claim & Unstake"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingMyRewards;
