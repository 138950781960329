import React, { FC } from "react";
import { navigate } from "gatsby";
import { Pool } from "../../../models/pools";
import Button from "../../common/button/button";

interface StakingSushiStepsProps {
  poolDetails: Pool;
  dismiss: () => void;
}

const StakingSushiSteps: FC<StakingSushiStepsProps> = ({
  poolDetails,
  dismiss,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-9 gap-16 md:gap-24">
      <div
        className="md:col-span-3 bg-black"
        style={{
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="text-white px-24 space-y-16 my-24">
          <h1 className="text-white">Step 1</h1>
          <p className="body1 text-dark-white-64">
            Acquire the Liquidity Pool (LP) tokens on Sushiswap
          </p>
          <Button fill={true} width="full" onClick={() => navigate(`/`)}>
            Get LP token on sushiswap
          </Button>
        </div>
      </div>
      <div className="md:col-span-3">
        <div
          className="bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="text-white p-24 space-y-16">
            <h1 className="text-white">Step 2</h1>
            <p className="body1 text-dark-white-64">Stake LP tokens below</p>
          </div>
        </div>
        <div className="flex flex-row justify-end pt-24">
          <button
            className="bg-transparent text-dark-red text-left btn-second rounded-md hover:opacity-80 active:opacity-75 focus:ring-transparent focus:outline-none"
            onClick={dismiss}
          >
            Dismiss instructions
          </button>
        </div>
      </div>
    </div>
  );
};

export default StakingSushiSteps;
