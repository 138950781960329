import { useEffect, useState } from "react";
import Api from "../../services/api";
import { Pool } from "../../models/pools";

export const useGetPoolDetails = (poolID: number) => {
  const [poolLoading, setPoolLoading] = useState<boolean>(true);
  const [poolError, setPoolError] = useState<any>();
  const [poolDetails, setPoolDetails] = useState<Pool>();

  useEffect(() => {
    const api = new Api();

    api
      .getPoolDetails(poolID)
      .then((response) => {
        setPoolDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
        setPoolError(error);
      })
      .finally(() => {
        setPoolLoading(false);
      });
  }, []);

  return { poolDetails, poolLoading, poolError };
};
