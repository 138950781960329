import React, { FC, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { navigate } from "gatsby";

import { useGetPoolDetails } from "../hooks/farm/useGetPoolDetails";
import { useAuthentication } from "../hooks/auth/useLogin";

import Layout from "../components/layout";
import Loading from "../components/loading/loading";
import StakingOverview from "../components/farm/staking/stakingOverview";
import StakingMyRewards from "../components/farm/staking/stakingMyRewards";
import StakingDialog from "../components/farm/staking/stakingDialog";
import StakingSushiSteps from "../components/farm/staking/stakingSushiSteps";
import SEO from "../components/seo";
import { useFeature } from "../hooks/feature/useFeature";

interface StakingPageProps {
  poolID: number;
}

const StakingPage: FC<StakingPageProps> = ({ poolID }) => {
  const [farmFeatureEnabled, farmFeatureLoading, farmFeatureError] =
    useFeature("farm");

  useEffect(() => {
    if (!farmFeatureEnabled) {
      navigate("/explore");
    }
  }, []);

  const { poolDetails, poolLoading, poolError } = useGetPoolDetails(poolID);
  const { isLoggedIn, user, authLoading, error } = useAuthentication();
  const [showSushiSteps, setShowSushiSteps] = useState<boolean>(true);

  const dismissSushiSteps = () => {
    setShowSushiSteps(false);
  };

  // TODO: LINEAR(SZN-706) Map ICON & COLOR on Front-End based on POOL Token

  return (
    <Layout>
      <SEO title="Staking" />
      {poolLoading || authLoading ? (
        <div className="flex flex-col h-full place-content-center">
          <div className="mt-16 mx-auto">
            <Loading size={32} />
          </div>
        </div>
      ) : poolDetails ? (
        <div className="h-full flex flex-col w-full">
          <div
            className="h-full flex flex-col space-y-24 md:space-y-48 self-center w-full"
            style={{ maxWidth: "1024px" }}
          >
            <div className="flex flex-row w-full">
              <button
                onClick={() => {
                  navigate("/farm");
                }}
              >
                <div className="flex flex-row items-center text-dark-white-64">
                  <ArrowBackIcon className="w-24 h-24" />
                  <p className="btn-primary ml-20">Farm</p>
                </div>
              </button>
            </div>
            <div className="flex flex-row items-center pl-8">
              <img
                src="https://placeimg.com/48/48/any"
                className="w-68 h-68 rounded-full"
              />
              <div
                className="flex flex-col ml-16"
                style={
                  poolDetails?.color
                    ? { color: poolDetails.color }
                    : { color: "#ff5574" }
                }
              >
                <p className="display2">{poolDetails.name}</p>
                {poolDetails.type !== "sushi_liquidity" ? null : (
                  <p className="body2">
                    {poolDetails.name.replace("/", "_")}_SUSHI_LP
                  </p>
                )}
              </div>
            </div>
            {poolDetails.type === "sushi_liquidity" && showSushiSteps && (
              <StakingSushiSteps
                poolDetails={poolDetails}
                dismiss={dismissSushiSteps}
              />
            )}
            <StakingOverview poolDetails={poolDetails} />
            <div className="grid grid-cols-1 md:grid-cols-9 gap-24">
              <div className="md:col-span-3">
                <StakingMyRewards user={user} poolID={poolDetails.id} />
              </div>
              <div className="md:col-span-4">
                <StakingDialog user={user} poolDetails={poolDetails} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-full justify-center">
          <h1 className="text-white text-center">
            There is no pool information available for this pool ID
          </h1>
        </div>
      )}
    </Layout>
  );
};

export default StakingPage;
