import React, { FC, useState } from "react";
import { Pool } from "../../../models/pools";
import StakeTab from "./tabs/stakeTab";
import UnstakeTab from "./tabs/unstakeTab";

enum TAB {
  STAKE,
  UNSTAKE,
}

interface StakingDialogProps {
  user: any;
  poolDetails: Pool;
}

const StakingDialog: FC<StakingDialogProps> = ({ user, poolDetails }) => {
  const [openTab, setOpenTab] = useState<TAB>(TAB.STAKE);

  return (
    <div className="flex flex-col w-full pb-48">
      <h1 className="pb-24 text-dark-white-64">Staking</h1>
      <div className="flex flex-row w-full">
        <div
          className="flex flex-grow bg-primary-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="w-full">
            <div className="flex flex-row w-full">
              <div className="flex flex-col w-full py-24 mx-20 space-y-24">
                <div className="flex flex-row">
                  <div
                    className="flex w-1/2 cursor-pointer focus:ring-transparent focus:outline-none"
                    style={
                      openTab === TAB.STAKE
                        ? {
                            background:
                              "linear-gradient(270deg, #FF4041 0%, #FF6190 29.69%, #FF8B4B 57.81%, #85D6F7 100%)",
                          }
                        : {
                            backgroundColor: "#000000",
                            borderBottom: "2px solid #333333",
                          }
                    }
                    onClick={() => {
                      setOpenTab(TAB.STAKE);
                    }}
                  >
                    <div className="flex w-full mb-2 justify-center bg-black">
                      <h2
                        className={
                          "pb-12 " +
                          (openTab === TAB.STAKE
                            ? "horiz-gradient text-transparent bg-clip-text"
                            : "text-dark-white-64")
                        }
                      >
                        Stake
                      </h2>
                    </div>
                  </div>
                  <div
                    className="flex w-1/2 cursor-pointer focus:ring-transparent focus:outline-none"
                    style={
                      openTab === TAB.UNSTAKE
                        ? {
                            background:
                              "linear-gradient(270deg, #FF4041 0%, #FF6190 29.69%, #FF8B4B 57.81%, #85D6F7 100%)",
                          }
                        : {
                            backgroundColor: "#000000",
                            borderBottom: "2px solid #333333",
                          }
                    }
                    onClick={() => {
                      setOpenTab(TAB.UNSTAKE);
                    }}
                  >
                    <div
                      className="flex w-full justify-center bg-black"
                      style={{ marginBottom: "2px" }}
                    >
                      <h2
                        className={
                          "pb-12 " +
                          (openTab === TAB.UNSTAKE
                            ? "horiz-gradient text-transparent bg-clip-text"
                            : "text-dark-white-64")
                        }
                      >
                        Unstake
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="flex-row w-full">
                  {openTab === TAB.STAKE && (
                    <StakeTab user={user} poolDetails={poolDetails} />
                  )}
                  {openTab === TAB.UNSTAKE && (
                    <UnstakeTab user={user} poolDetails={poolDetails} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingDialog;
