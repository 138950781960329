import React from "react";
import { FC } from "react";
import { useAuthentication } from "../../../hooks/auth/useLogin";
import Loading from "../../loading/loading";
import Login from "../../wallet/login";
import Button from "../button/button";

interface TransactButtonProps {}

//This is a generic container that will return the wallet connect button if no wallet connected
const ConnectContainer: FC<TransactButtonProps> = ({ children }) => {
  const { isLoggedIn } = useAuthentication();

  if (!isLoggedIn) {
    return <Login size="lg" width="full" />;
  } else {
    return <>{children}</>;
  }
};

export default ConnectContainer;
