import {
  TransactionStatus,
  useContractCall,
  useContractFunction,
  useEthers,
} from "@usedapp/core";
import { BigNumber, Contract } from "ethers";
import { formatUnits, Interface, parseUnits } from "ethers/lib/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ABI } from "../../../constants";
import { CONTRACT_ADDRS } from "../../../constants/contractAddrs";

export const useStakedAmount = (poolId: number, address: string) => {
  const [stakedAmount, setStakedAmount] = useState<number>();

  //This polls every block for updates
  const values = useContractCall({
    abi: new Interface(ABI.SZNS_CHEF),
    address: CONTRACT_ADDRS.SZNS_CHEF,
    args: [poolId, address],
    method: "userInfo",
  }) as [BigNumber, BigNumber];

  useEffect(() => {
    if (values) {
      setStakedAmount(parseFloat(formatUnits(values[0])));
    } else {
      setStakedAmount(undefined);
    }
  }, [values]);

  return stakedAmount;
};

export const useStake = (
  poolId: number
): [(amount: number) => void, TransactionStatus] => {
  const { library } = useEthers();

  const contract = useMemo(() => {
    return new Contract(
      CONTRACT_ADDRS.SZNS_CHEF,
      ABI.SZNS_CHEF,
      library ? library.getSigner() : undefined
    );
  }, [library]);

  const { state, send } = useContractFunction(contract, "deposit");

  //Returns memoized function for approve contract call
  const stake = useCallback((amount: number) => {
    //Assuming all pool assets are 1e18
    send(poolId, parseUnits(amount.toString()));
  }, []);

  return [stake, state];
};

export const useUnstake = (
  poolId: number
): [(amount: number) => void, TransactionStatus] => {
  const { library } = useEthers();

  const contract = useMemo(() => {
    return new Contract(
      CONTRACT_ADDRS.SZNS_CHEF,
      ABI.SZNS_CHEF,
      library ? library.getSigner() : undefined
    );
  }, [library]);

  const { state, send } = useContractFunction(contract, "withdraw");

  //Returns memoized function for approve contract call
  const stake = useCallback((amount: number) => {
    //Assuming all pool assets are 1e18
    send(poolId, parseUnits(amount.toString()));
  }, []);

  return [stake, state];
};
