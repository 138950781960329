import { useEffect, useState } from "react";
import Api from "../../services/api";

export const useUserInfoForPool = (address: string, poolID: number) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [userInforForPool, setUserInforForPool] = useState<any>();

  useEffect(() => {
    if (address) {
      const api = new Api();

      api
        .getUserInfoForPool(address, poolID)
        .then((response) => {
          setUserInforForPool(response.data);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return [userInforForPool, loading, error];
};
