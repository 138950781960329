import React, { FC, useState } from "react";
import Button from "../../../common/button/button";
import TokenInput from "../../../swap/input/tokenInput";
import { Pool } from "../../../../models/pools";
import { useApproved, useBalance } from "../../../../hooks/erc20/useErc20";
import { formatUnits } from "ethers/lib/utils";
import Loading from "../../../loading/loading";
import ConnectContainer from "../../../common/web3/connectContainer";
import {
  useStake,
  useStakedAmount,
} from "../../../../hooks/album/farm/useStake";
import { CONTRACT_ADDRS } from "../../../../constants/contractAddrs";

interface StakeTabProps {
  user: any;
  poolDetails: Pool;
}

const StakeTab: FC<StakeTabProps> = ({ user, poolDetails }) => {
  // hook to check user's wallet to see if token is enabled based on profile address & token name. Returns enabled/staked amount/wallet balance
  const tokenBalance = useBalance(poolDetails.token, user?.uid);

  const [amount, setAmount] = useState<number>();

  const stakedAmount = useStakedAmount(poolDetails.id, user?.uid);
  const [stake, stakeState] = useStake(poolDetails.id);

  const [allowance, approve, approveState] = useApproved(
    CONTRACT_ADDRS.SZNS_CHEF,
    poolDetails.token
  );

  const handleStake = () => {
    stake(amount);
  };

  const handleMax = () => {
    setAmount(parseFloat(tokenBalance));
  };

  const handleChange = (value) => {
    setAmount(value.value);
  };

  return (
    <div className="flex flex-col w-full space-y-24">
      <div className="flex flex-row justify-start space-x-32">
        <div className="flex flex-col">
          <p className="caption text-dark-white-64 pb-4">STAKED AMOUNT</p>
          {!stakedAmount || stakedAmount === 0 ? (
            <h2 className="text-dark-light-grey animate-pulse">
              --- {poolDetails.name}
            </h2>
          ) : (
            <h2 className="text-dark-light-grey fade-in">
              {stakedAmount.toLocaleString()} {poolDetails.name}
            </h2>
          )}
        </div>
        <div className="flex flex-col">
          <p className="caption text-dark-white-64 pb-4">WALLET BALANCE</p>
          <h2
            className={`text-dark-light-grey fade-in ${
              tokenBalance === "---" ? "animate-pulse" : ""
            }`}
          >
            {tokenBalance.toLocaleString()} {poolDetails.name}
          </h2>
        </div>
      </div>
      <div className="flex flex-row w-full space-x-16">
        <div className="flex w-2/3">
          <TokenInput
            placeholder="0.0"
            value={amount?.toLocaleString()}
            onChange={handleChange}
            decimalScale={6}
            onMaxClicked={handleMax}
          />
        </div>
        <div className="flex w-1/3">
          <Button
            fill={true}
            width="full"
            disabled={Boolean(
              !allowance ||
                parseFloat(formatUnits(allowance)) === 0 ||
                !user ||
                amount > parseFloat(tokenBalance) ||
                amount <= 0 ||
                !amount ||
                stakeState.status === "Mining"
            )}
            onClick={handleStake}
          >
            {stakeState.status === "Mining" ? <Loading size={24} /> : "Stake"}
          </Button>
        </div>
      </div>
      <ConnectContainer>
        <div
          className={`${
            allowance && parseFloat(formatUnits(allowance)) > 0 ? "hidden" : ""
          } flex`}
        >
          <Button
            width="full"
            fill={true}
            onClick={approve}
            disabled={approveState.status === "Mining"}
          >
            {approveState.status === "Mining" ? (
              <Loading size={24} />
            ) : (
              `Enable ${poolDetails.name}`
            )}
          </Button>
        </div>
      </ConnectContainer>
    </div>
  );
};

export default StakeTab;
