import React, { FC } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Pool } from "../../../models/pools";
import { ETHERSCAN_CONTRACT } from "../../../constants";
import { CONTRACT_ADDRS } from "../../../constants/contractAddrs";

interface StakingOverviewProps {
  poolDetails: Pool;
}

const StakingOverview: FC<StakingOverviewProps> = ({ poolDetails }) => {
  return (
    <div className="flex flex-col w-full sm:px-16 md:px-0">
      <h1 className="text-dark-white-64 pb-4">Overview</h1>
      <div className="pb-24">
        <a
          href={`${ETHERSCAN_CONTRACT}/${CONTRACT_ADDRS.SZNS_CHEF}`}
          className="bg-transparent text-dark-red text-left btn-second rounded-md hover:opacity-80 active:opacity-75 focus:ring-transparent focus:outline-none"
        >
          View contract on etherscan
        </a>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
        <div
          className="col-span-1 bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 space-y-16 my-24 w-full">
            <div className="flex flex-row w-full text-dark-white-64">
              <p className="caption flex flex-grow">APR</p>
              <div>
                <InfoIcon />
              </div>
            </div>
            <h2 className="pb-4 text-dark-light-grey">
              {parseFloat(poolDetails.apr.toFixed(2)).toLocaleString()}%
            </h2>
          </div>
        </div>
        <div
          className="col-span-1 bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 my-24 w-full">
            <div className="flex flex-row w-full text-dark-white-64 pb-16">
              <p className="caption flex flex-grow">Pool Balance</p>
              <div>
                <InfoIcon />
              </div>
            </div>
            <h2 className="pb-4 text-dark-light-grey">
              ${poolDetails.poolBalance.toLocaleString()}
            </h2>
          </div>
        </div>
        <div
          className="col-span-1 bg-black"
          style={{
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="px-24 my-24 w-full">
            <div className="flex flex-row w-full text-dark-white-64 pb-16">
              <p className="caption flex flex-grow">Total SZNS Rewards</p>
              <div>
                <InfoIcon />
              </div>
            </div>
            <h2 className="pb-4 text-dark-light-grey">
              {poolDetails.poolReward.toLocaleString()} SZNS
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingOverview;
